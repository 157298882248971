import React, { useEffect, useState } from "react";
import { FaLessThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Select, Input, Upload, message, Space, Modal, Typography } from "antd";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { MdClear } from "react-icons/md";
import { ApiEndPoints } from "./Api/Apiendpoints";
import { postProducts, getProduct,updateProduct } from "./Api/Apicalls";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { API_URL } from "./Api/Config";

// Custom TabPanel for Tabs component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const { Dragger } = Upload;

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Accessible properties for Tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "20px",
  borderRadius: "10px",
  p: 5,
};

export default function EditProduct() {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(""); 
  const [productData, setProductData] = useState({
    category: "",
    productId: "",
    name: "",
    minWeightInGrams: null,
    price: "",
    description: "",
    deliveryInfo: "",
    nutritionalInfo: "",
    image: "",
    metaHeading:"",
    metaContent:"",
    metaKeys:""
  });

  // Getsingledata
  const handleGetProduct = async () => {
    try {
      const res = await getProduct(ApiEndPoints("getproduct"), id);
      setProductData(res.product);
      setImagePreview(res.product.image); 
    } catch (err) {
      console.error(err);
    }
  };

  // Handle tab changes
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Handle modal open and close
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle form changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleSelectChange = (value, name) => {
    setProductData({ ...productData, [name]: value });
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      // Store the selected file in the state
      setFile(file);
      // Preview the image
      convertToBase64(file).then((base64) => {
        setImagePreview(base64);
      });
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const navigate=useNavigate()

  // Handle product posting
  const handlePatchProduct = async () => {

    

    try {
      // Create a new FormData object
      const formData = new FormData();
      // Append form fields to FormData
      formData.append("category", productData.category);
      formData.append("productId", productData.productId);
      formData.append("name", productData.name);
      formData.append("minWeightInGrams", productData.minWeightInGrams);
      formData.append("price", productData.price);
      formData.append("description", productData.description);
      formData.append("deliveryInfo", productData.deliveryInfo);
      formData.append("nutritionalInfo", productData.nutritionalInfo);
      formData.append("metaHeading", productData.metaHeading);
      formData.append("metaContent", productData.metaContent);
      formData.append("slug", productData.slug);
      formData.append("metaKeys", productData.metaKeys);

      

      if (file) {
        formData.append("image", file);
      } else {
        formData.append("image", productData.image);
      }
      


      // Make the API request
      const response=await updateProduct(ApiEndPoints("updateProduct"),id,formData)
      setProductData({
        category: "",
        productId: "",
        name: "",
        minWeightInGrams: null,
        price: "",
        description: "",
        deliveryInfo: "",
        nutritionalInfo: "",
        image: "", 
        metaContent:"",
        metaHeading:"",
        slug:"",
        metaKeys:""
      });
      setFile(null);
      setImagePreview("");
      navigate("/products")
      console.log(response, "res");
    } catch (error) {
      console.error(error);
      alert("Error posting product");
    }
  };

  useEffect(() => {
    handleGetProduct();
  }, []);

  return (
    <div>
 

      <div className="product-margin">
        <h3>
          <Link to="/products" activeStyle>
            <FaLessThan style={{ color: "#0d354f" }} />
          </Link>
          &nbsp;Edit Product
        </h3>
        <div className="product-combine">
          <div className="addnew-contain">
            {/* Display current image or preview */}
            {imagePreview ? (
              <div style={{ marginBottom: "20px" }}>
                <img
                  src={file ? imagePreview : `${API_URL}/images/${imagePreview}`}
                  alt="Product Preview"
                  style={{ maxWidth: "300px", maxHeight: "300px" }}
                />
              </div>
            ) : (
              <div style={{ marginBottom: "20px" }}>
                <img
                  src={`${API_URL}/images/${productData.image}`}
                  alt="Product Preview"
                  style={{ maxWidth: "300px", maxHeight: "300px" }}
                />
              </div>
            )}
            <div style={{ marginTop: "40px" }}>
              <label>Select categories</label>
              <br />
              <Space wrap>
                <Select
                  name="category"
                  value={productData.category}
                  className="select-pending product-search-new"
                  onChange={(value) => handleSelectChange(value, "category")}
                  options={[
                    { value: "chicken", label: "Chicken" },
                    { value: "mutton", label: "Mutton" },
                  ]}
                />
              </Space>
            </div>
            {/* <div style={{ marginTop: "10px" }}>
              <label>Product Id</label>
              <br />
              <Input
                name="productId"
                value={productData.productId}
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                className="select-pending product-search-new"
              />
            </div> */}
            <div style={{ marginTop: "10px" }}>
              <label>Product Name</label>
              <br />
              <Input
                name="name"
                value={productData.name}
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <label>Image (Recommended 292*400 px)</label>
              <br />
              <div>
                <Dragger {...props} className="dragger-box">
                  <p className="ant-upload-drag-icon mb-0">
                    <MdOutlineCloudUpload
                      style={{ color: "#0D354F", fontSize: "3rem" }}
                    />
                  </p>
                  <p className="ant-upload-text mb-0">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                gap: "25px",
                alignItems: "end",
              }}
            >
              <div>
                <label>Weight (Min)</label>
                <br />
                {/* <Select
                  name="minWeightInGrams"
                  value={productData.minWeightInGrams}
                  style={{ width: 120 }}
                  className="select-pending product-search-gram"
                  onChange={(value) => handleSelectChange(value, "minWeightInGrams")}
                  options={[
                    { value: 250, label: "250 g" },
                    { value: 500, label: "500 g" },
                    { value: 750, label: "750 g" },
                    { value: 1000, label: "1000 g" },
                  ]}
                /> */}
                    <Input
                  name="minWeightInGrams"
                  value={productData.minWeightInGrams}
                  onChange={handleInputChange}
                  style={{ width: "115px", height: "35px", color: "black" }}
                  placeholder=" "
                  className="product-search-gram"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <label>Price</label>
                <br />
                <Input
                  name="price"
                  value={productData.price}
                  onChange={handleInputChange}
                  style={{ width: "115px", height: "35px", color: "black" }}
                  placeholder=" "
                  className="product-search-gram"
                />
              </div>
            </div>
            {/* <div style={{ marginTop: "10px" }}>
            <label>Piece</label>
                <br />
                <Input
                name="piece"
                value={productData.metaHeading}
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div> */}
            <div style={{ marginTop: "10px" }}>
            <label>Meta Heading</label>
                <br />
                <Input
                name="metaHeading"
                value={productData.metaHeading}
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div>
            <div style={{ marginTop: "10px" }}>
            <label>Meta Content</label>
                <br />
                <textarea
                    name="metaContent"
                    value={productData.metaContent}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
            </div>

            <div style={{ marginTop: "10px" }}>
            <label>Meta Keys</label>
                <br />
                <textarea
                    name="metaKeys"
                    value={productData.metaKeys}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
            </div>
            
            <div style={{ marginTop: "10px" }}>
            <label>URL format(Eg:buy-mutton-kidney) <span style={{color:"red"}}>*</span></label>
                <br />
                <Input
                name="slug"
                value={productData.slug}
                onChange={handleInputChange}
                style={{ width: "400px", height: "35px", color: "black" }}
                placeholder=" "
                className="select-pending product-search-new"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Box sx={{ width: "100%" }} className="product-search-new-tab">
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="product tabs"
                  >
                    <Tab label="Product Description" {...a11yProps(0)} />
                    <Tab label="Delivery Information" {...a11yProps(1)} />
                    <Tab label="Nutritional Values" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <textarea
                    name="description"
                    value={productData.description}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <textarea
                    name="deliveryInfo"
                    value={productData.deliveryInfo}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <textarea
                    name="nutritionalInfo"
                    value={productData.nutritionalInfo}
                    onChange={handleInputChange}
                    className="text-area p-2"
                    rows={5}
                  ></textarea>
                </CustomTabPanel>
              </Box>
            </div>
            <div
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  color: "white",
                  backgroundColor: "#0d354f",
                  border: "none",
                  padding: "5px 50px",
                  borderRadius: "5px",
                }}
                onClick={handlePatchProduct}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
